import React from 'react'
import './Experience.css'
import {BsCloudCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <div>
      <section id='experience'>
        <h5>Skills I Have</h5>
        <h2>My Experience</h2>

        <div className="container experience_container">
          <div className="experience_frontend">
            <h3>Frontend Development</h3>
            <div className="experience_content">
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>HTML</h4>
                 <small className='text-light'>Experienced</small>
                 </div>
              </article>
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>CSS</h4>
                 <small className='text-light'>Experienced</small>
                 </div>
              </article>
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>JavaScript</h4>
                 <small className='text-light'>Intermediate</small>
                 </div>
              </article>
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>PHP</h4>
                 <small className='text-light'>Intermediate</small>
                 </div>
              </article>
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>Bootstrap</h4>
                 <small className='text-light'>Experienced</small>
                 </div>
              </article>
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>ReactJS</h4>
                 <small className='text-light'>Intermediate</small>
                 </div>
              </article>
            </div>
          </div>

        {/* ********** End of Frontend ************ */}

          <div className="experience_backend">

            <h3>  Backend Development</h3>
            <div className="experience_content">
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>Node JS</h4>
                 <small className='text-light'>Intermediate</small>
                 </div>
              </article>
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>MongoDB</h4>
                 <small className='text-light'>Intermediate</small>
                 </div>
              </article>
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>MySQL</h4>
                 <small className='text-light'>Beginner</small>
                 </div>
              </article>
            </div>
          </div>

        {/* ************ End of Backend *********** */}

        <div className="experience_languages">

            <h3>Other Languages</h3>
            <div className="experience_content">
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>C/C++</h4>
                 <small className='text-light'>Advanced</small>
                 </div>
              </article>
              <article className='experience_details'>
                 <BsCloudCheckFill className='experience_details-icon' />
                 <div>
                 <h4>Python</h4>
                 <small className='text-light'>Intermediate</small>
                 </div>
              </article>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default Experience
